export const inputType = Object.freeze({
   button: 'button',
   checkbox: 'checkbox',
   color: 'color',
   date: 'date',
   dateTimeLocal: 'datetime-local',
   email: 'email',
   file: 'file',
   hidden: 'hidden',
   image: 'image',
   month: 'month',
   number: 'number',
   password: 'password',
   radio: 'radio',
   range: 'range',
   reset: 'reset',
   search: 'search',
   submit: 'submit',
   tel: 'tel',
   text: 'text',
   time: 'time',
   url: 'url',
   week: 'week',
})
